import {DOCUMENT} from '@angular/common';
import {ChangeDetectionStrategy, Component, Inject, Input} from '@angular/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IonFab, IonFabButton, IonFabList, IonToast} from '@ionic/angular/standalone';

@Component({
  selector: 'cmn-fab-contact',
  templateUrl: './fab-contact.component.html',
  styleUrls: ['./fab-contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FontAwesomeModule, IonFabButton, IonFab, IonFabList, IonToast],
  standalone: true,
})
export class FabContactComponent {
  @Input({required: true}) mailLink: string;
  @Input({required: true}) instagramDirectMessageLink: string;
  @Input({required: true}) whatsAppLink: string;
  @Input({required: true}) facebookMessengerLink: string;
  isToastOpen = true;

  constructor(@Inject(DOCUMENT) private readonly document: Document) {}

  dismissToast(): void {
    this.isToastOpen = false;
  }

  shadowBottom(): void {
    // SUPER HACK pour modifier une position dans le shadow dom
    const element: Element = this.document.querySelector('ion-toast')?.shadowRoot?.querySelector('div.toast-wrapper');
    if (element) {
      element.setAttribute('style', 'bottom: 140px;');
    }
  }
}
