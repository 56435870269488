<ion-fab horizontal="end" slot="fixed" vertical="bottom">
  <ion-fab-button (click)="dismissToast()" aria-label="Contact" class="cmn-fab-contact__main-button" color="primary">
    <fa-icon aria-hidden="true" icon="comment-dots" size="2x" />
  </ion-fab-button>
  <ion-fab-list side="top">
    <ion-fab-button
      [href]="facebookMessengerLink"
      aria-label="Facebook-messenger"
      class="cmn-fab-contact__facebook-messenger-button"
      target="_blank"
    >
      <fa-icon [icon]="['fab', 'facebook-messenger']" aria-hidden="true" size="2x" />
    </ion-fab-button>
    <ion-fab-button [href]="instagramDirectMessageLink" aria-label="Instagram" class="cmn-fab-contact__instagram-button" target="_blank">
      <fa-icon [icon]="['fab', 'instagram']" aria-hidden="true" size="2x" />
    </ion-fab-button>
    <ion-fab-button [href]="whatsAppLink" aria-label="WhatsApp" class="cmn-fab-contact__whattsapp-button" target="_blank">
      <fa-icon [icon]="['fab', 'whatsapp']" aria-hidden="true" size="2x" />
    </ion-fab-button>
  </ion-fab-list>
  <ion-fab-list side="start">
    <ion-fab-button [href]="'mailto:' + mailLink" aria-label="Email" color="dark">
      <fa-icon aria-hidden="true" icon="envelope" />
    </ion-fab-button>
  </ion-fab-list>
</ion-fab>
<ion-toast
  #toast
  (click)="toast.dismiss()"
  (didDismiss)="isToastOpen = false"
  (ionToastDidPresent)="shadowBottom()"
  [isOpen]="isToastOpen"
  class="cmn-fab-contact__toast"
  duration="5000"
  message="Bug ou coquille à signaler ? C'est ici"
/>
